import moment from 'moment'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom === 'custom') {
      return {
        cell: row.service_status?.status?.name ?? '',
      }
    } else if (isCustom === 'date') {
      return {
        cell: row.target_close_date
          ? moment(row.target_close_date).format('DD-MM-YYYY')
          : ('' ?? ''),
      }
    } else if (isCustom === 'priority') {
      return {
        cell: (
          <>
            <span
              style={{
                color: row?.service_priority?.colour_code,
              }}
            >
              {getNestedProperty(row, key as string)}
            </span>
          </>
        ),
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Service ID',
      field: 'unique_id',
      ...defaultColumnProps3,
      fixed: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'serviceName'),
      customCell: true,
    },
    {
      title: 'Service Name',
      field: 'service_name',
      ...defaultColumnProps3,
      fixed: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'serviceName'),
      customCell: true,
    },

    {
      title: 'Service Team Lead',
      // renderCell: createRenderCell('team.lead.name'),
      field: 'vendor_team.lead.name',
      ...defaultColumnProps3,
      // customCell: true,
    },
    {
      title: 'Service Priority',
      renderCell: createRenderCell('service_priority.display_name', 'priority'),
      customCell: true,
      field: 'service_priority.display_name',
      ...defaultColumnProps3,
    },
    {
      title: 'TL Allocated On',
      field: 'assigned_date',
      ...defaultColumnProps3,
    },
    {
      title: 'Target Date',
      field: 'target_close_date',
      renderCell: createRenderCell('target_close_date', 'date'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Service Value',
      field: 'total_price',
      renderCell: createRenderCell('total_price', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Amt Allocated',
      field: 'service_allocated_amount',
      renderCell: createRenderCell('service_allocated_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Amt Pending',
      field: 'balance_amount_to_allocate',
      renderCell: createRenderCell('balance_amount_to_allocate', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Service Status',
      field: 'name',
      ...defaultColumnProps3,
      renderCell: createRenderCell('service_status', 'custom'),
      customCell: true,
    },
  ]

  return column
}
