import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from '..'
import MenuDropDown from '../../../components/common/customMenuDropdown/MenuDropDown'
import { useAppStore } from '../../../store/appStore'
import DynamicDropdown from '../DynamicDropdown'
import Icons from '../icons'
import SelectDropdown from '../selectDropdown'

type TileProps = {
  id: number
  icon?: string
  value: string
  label?: string
  getData?: (search: string, page: number) => any
  handleAction?: any
  className?: string
  hasDropdown?: boolean
  disabled?: boolean
  enabled?: boolean
  slug?: string
  optional?: boolean
}

type SectionProps = {
  id: number
  className?: string
  tiles: TileProps[]
  hidden?: boolean
}
type ChipProps = {
  id: number
  status: string
}
type BasicDataProps = {
  title: string | number
  subtitle?: string | number
  image?: string
  subLink?: string
  type?: string

  icon?: string
  count?: string | number
  status?: string
}
type ActionProps = {
  id: number
  label: string
  disabled?: boolean
  icon?: string
  isOutlined?: boolean
}
// type MenuItem = {
//   value: string
//   label: string
// }
type headerActionProps = {
  label?: string
  onClick?: () => void
  hidden?: boolean
  disabled?: boolean
  url?: string
}

type headerMenuItems = {
  id?: number
  label?: string
  Action?: () => void
  hidden?: boolean
  menuItems?: any
}
type DetailTileProps = {
  tiledata?: SectionProps[]
  chipdata?: ChipProps[]
  data: BasicDataProps
  onActionClick?: (id: number) => void
  actions?: ActionProps[]
  menuItems?: any
  setUpdateCREId?: any
  handleStatus?: (val: string, sel: any) => void
  statusDropDown?: any
  disabled?: boolean
  disableStatus?: boolean
  unAllocate?: boolean
  headerAction?: headerActionProps
  headerMenuItems?: headerMenuItems[]
  priorityDataDropDown?: any
  crePermission?: boolean
  statusDisabled?: boolean
}

const DetailHeader: React.FC<DetailTileProps> = ({
  tiledata,
  chipdata,
  onActionClick,
  actions,
  data,
  menuItems = [],
  disabled = false,
  setUpdateCREId,
  handleStatus,
  statusDropDown,
  unAllocate = false,
  headerAction,
  headerMenuItems,
  priorityDataDropDown,
  crePermission = true,
  statusDisabled,
}) => {
  const { isLoading } = useAppStore()
  return (
    <>
      {isLoading ? (
        <div className="p-4 flex justify-between flex-wrap gap-3 items-center border-b border-formBorder">
          <div className="flex items-center w-full">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
            <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
            <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-72"></div>
            <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-72"></div>
          </div>
          <div className="flex items-center w-full">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
            <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
            <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-72"></div>
            <div className="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-72"></div>
          </div>
        </div>
      ) : (
        <div className="p-4 flex justify-between flex-wrap gap-3 items-center border-b border-formBorder">
          <div className="flex gap-3">
            <div className="flex items-center flex-wrap gap-5 text-[#222]  dark:text-white">
              <div className="flex items-center gap-3">
                {data?.image && (
                  <div className="w-10 h-10">
                    <img src={data?.image} className="w-10 h-10" alt="" />
                  </div>
                )}
                <div className="">
                  <div className="font-bold text-common dark:text-white">
                    {data.title}{' '}
                    {data?.status && (
                      <span className={`statusLabel ${data.status}`}>
                        {data?.status}
                      </span>
                    )}
                    {data?.type && (
                      <span className={`typeLabel `}>{data?.type}</span>
                    )}
                  </div>
                  {data?.subtitle && (
                    <>
                      {data?.subLink ? (
                        <>
                          <Link to={data?.subLink} className="text-xxs">
                            {data?.subtitle}
                          </Link>
                          {data?.count ? ` + ${data?.count} ` : ''}
                        </>
                      ) : (
                        <div className="text-xxs">{data?.subtitle}</div>
                      )}
                    </>
                  )}
                </div>
                {chipdata && chipdata?.length && (
                  <div className="gap-5  items-center justify-center hidden">
                    {chipdata?.map((chip) => (
                      <div
                        className="flex items-center justify-center"
                        key={chip.id}
                      >
                        <div>
                          <svg
                            width="9"
                            height="20"
                            viewBox="0 0 9 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.81616 19H2.87742C1.77285 19 0.877421 18.1046 0.877421 17V7.57305C0.877421 6.95389 1.16418 6.36964 1.65398 5.9909L7.56785 1.41785C7.91816 1.14696 8.34846 1 8.79129 1H8.81616"
                              stroke="#273EC3"
                              strokeWidth="1"
                            />
                            <path
                              d="M2.87742 19H8.81616V1.52772C8.81616 1.23627 8.5799 1 8.28845 1C8.1716 1 8.05806 1.03878 7.96563 1.11025L1.65398 5.9909C1.16418 6.36964 0.877421 6.95389 0.877421 7.57305V17C0.877421 18.1046 1.77285 19 2.87742 19Z"
                              fill="#273EC3"
                              fillOpacity="0.08"
                            />
                          </svg>
                        </div>
                        <div className="status-polygon ml-[-1px] text-[#273EC3]">
                          {chip.status}
                        </div>
                        <div className="ml-[-1px]">
                          <svg
                            width="10"
                            height="20"
                            viewBox="0 0 10 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.816162 1H6.7549C7.85947 1 8.7549 1.89543 8.7549 3V12.427C8.7549 13.0461 8.46814 13.6304 7.97834 14.0091L2.06448 18.5822C1.71417 18.853 1.28386 19 0.841038 19H0.816162"
                              stroke="#273EC3"
                              strokeWidth="1"
                            />
                            <path
                              d="M6.7549 1H0.816162V18.4723C0.816162 18.7637 1.05243 19 1.34388 19C1.46072 19 1.57426 18.9612 1.66669 18.8897L7.97834 14.0091C8.46814 13.6304 8.7549 13.0461 8.7549 12.427V3C8.7549 1.89543 7.85947 1 6.7549 1Z"
                              fill="#273EC3"
                              fillOpacity="0.08"
                            />
                          </svg>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {tiledata && tiledata.length && (
                <div className="flex gap-x-5 gap-y-3  items-center flex-wrap">
                  {tiledata?.map((section) => (
                    <>
                      {!section?.hidden && (
                        <div
                          className="flex bg-bgGrey dark:bg-[#545050] rounded-lg gap-x-7 gap-y-3 flex-wrap px-4 py-3"
                          key={section.id}
                        >
                          {section.tiles.map((tile) =>
                            (tile.optional && tile.value !== '--') ||
                            !tile.optional ? (
                              <div className="flex items-center" key={tile.id}>
                                {tile.label == 'Primary CRE' ||
                                tile.hasDropdown ||
                                tile.slug === 'service_status' ||
                                tile.slug === 'service_priority' ? (
                                  <div
                                    className={`flex gap-1 items-center w-auto`}
                                  >
                                    <div className="flex items-center justify-center">
                                      <span
                                        className={
                                          'text-sm text-secondary dark:text-white font-semibold'
                                        }
                                      >
                                        {tile.label}
                                      </span>
                                    </div>

                                    {tile.slug === 'service_status' ||
                                    tile.slug === 'service_priority' ? (
                                      <div className="relative">
                                        {tile.slug === 'service_status' && (
                                          <SelectDropdown
                                            tileItem={tile}
                                            disabled={statusDisabled}
                                            dropdownMenu={statusDropDown}
                                            setUpdateCREId={
                                              tile?.handleAction ?? handleStatus
                                            }
                                            unAllocate={true}
                                            slug={tile.slug}
                                            // other props you might need
                                          />
                                        )}
                                        {tile.slug === 'service_priority' && (
                                          <SelectDropdown
                                            tileItem={tile}
                                            disabled={
                                              tile.disabled ||
                                              (disabled &&
                                                Boolean(!tile.enabled)) ||
                                              !crePermission
                                            }
                                            dropdownMenu={priorityDataDropDown}
                                            setUpdateCREId={
                                              tile?.handleAction ?? handleStatus
                                            }
                                            unAllocate={true}
                                            slug={tile.slug}
                                            // other props you might need
                                          />
                                        )}{' '}
                                      </div>
                                    ) : (
                                      <div className="relative">
                                        {tile.getData ? (
                                          <DynamicDropdown
                                            tileItem={tile}
                                            disabled={
                                              tile.disabled ||
                                              (disabled &&
                                                Boolean(!tile.enabled)) ||
                                              !crePermission
                                            }
                                            getData={tile.getData}
                                            unAllocate={unAllocate}
                                            setUpdateCREId={(vl: any) =>
                                              setUpdateCREId(vl)
                                            }
                                          />
                                        ) : (
                                          <SelectDropdown
                                            tileItem={tile}
                                            disabled={
                                              tile.disabled ||
                                              (disabled &&
                                                Boolean(!tile.enabled)) ||
                                              !crePermission
                                            }
                                            unAllocate={unAllocate}
                                            dropdownMenu={menuItems}
                                            setUpdateCREId={(vl: any) =>
                                              setUpdateCREId(vl)
                                            }
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className={`flex gap-1 items-center w-auto`}
                                  >
                                    <div className="text-sm text-primaryText dark:text-white">
                                      {tile.icon ? (
                                        <Icons
                                          className="iconWidthSm relative"
                                          name={`${tile.icon}`}
                                        />
                                      ) : (
                                        <span
                                          className={
                                            tile.className ??
                                            ' text-sm text-secondary  dark:text-white font-semibold'
                                          }
                                        >
                                          {tile.label}
                                        </span>
                                      )}
                                    </div>

                                    <div
                                      className={
                                        tile.className ??
                                        'text-sm text-primaryText font-bold dark:text-white'
                                      }
                                    >
                                      {tile.value && tile.value !== ''
                                        ? tile.value
                                        : '--'}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null
                          )}
                        </div>
                      )}
                    </>
                  ))}
                  {headerAction &&
                    Object.keys(headerAction)?.length > 0 &&
                    !headerAction?.hidden && (
                      <div className="flex justify-end">
                        <Link
                          to={
                            headerAction?.disabled
                              ? '#/'
                              : (headerAction.url ?? '')
                          }
                          className={`bg-primary rounded-sm p-2 shadow-sm  text-sm text-white ${
                            headerAction?.disabled
                              ? 'opacity-70 cursor-not-allowed'
                              : ''
                          }`}
                          onClick={() =>
                            headerAction?.disabled
                              ? undefined
                              : headerAction?.onClick?.()
                          }
                          hidden={headerAction?.hidden}
                        >
                          {headerAction?.label}
                        </Link>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
          {actions && actions.length > 0 && (
            <div className="flex gap-2">
              {actions.map((action) => (
                <div key={action.id}>
                  <Button
                    label={action.label}
                    icon={action.icon}
                    disabled={action.disabled}
                    outlined={Boolean(action.isOutlined)}
                    onClick={() => onActionClick?.(action.id)}
                  />
                </div>
              ))}
            </div>
          )}
          {headerMenuItems && headerMenuItems?.length > 0 && (
            <MenuDropDown
              iconName="three_dot"
              headerMenuItems={headerMenuItems}
            />
          )}
        </div>
      )}
    </>
  )
}

export default DetailHeader
